<template>
  <div class="login-container">
    <div class="login-body">
      <div class="globe"></div>
      <a-form ref="form" :model="form" class="login-form">
        <img class="title" src="@/assets/logo-word-blue.png" />
        <a-form-item class="input-box">
          <a-input v-model="form.username" placeholder="请输入账号">
            <img
              width="16"
              height="18"
              src="@/assets/login/account.png"
              slot="prefix"
              alt=""
            />
          </a-input>
        </a-form-item>
        <a-form-item class="input-box">
          <a-input-password v-model="form.password" placeholder="请输入密码">
            <img
              width="14"
              height="18"
              src="@/assets/login/password.png"
              slot="prefix"
              alt=""
            />
          </a-input-password>
          <p class="rem-pwd">
            <a-checkbox @change="remPwdHandle"> 记住密码 </a-checkbox>
          </p>
        </a-form-item>

        <a-form-item class="sub-btn">
          <a-button
            style="width: 280px; height: 46px"
            type="primary"
            shape="round"
            @click="onSubmit"
            >立即登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="footer">
      <div>©2018-2021 西安笛卡尔信息技术有限公司</div>
    </div>
  </div>
</template>

<script>
import http from "@/config/axios.config";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    onSubmit() {
      http({
        url: `/rest/betaGISUI/login`,
        method: "post",
        data: { username: this.form.username, password: this.form.password },
      }).then((res) => {
        // console.log(res);
        if (res.data && res.data.success) {
          // console.log(res);
          window.sessionStorage.setItem("username", this.form.username);
          window.sessionStorage.setItem("userId", 1);
          window.sessionStorage.setItem("token", res.data.data.token)
          this.$router.push({ path: "/home" });
          console.log("登陆成功！");
        } else {
          this.$message.error(`登录失败, 请检查用户名或密码是否正确！`);
        }
      });
    },
    remPwdHandle(val) {
      if (val) {
        console.log("记住密码");
      }
    },
  },
};
</script>
<style lang="less" scoped>
/* 背景 */
.login-container {
  width: 100%;
  height: 100%;
  background: url("../../../assets/login/background.png");
  background-size: 100% 100%;
  align-items: center;
  .login-body {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .globe {
      width: 400px;
      height: 400px;
      background: url("../../../assets/login/globe.png");
      background-size: cover;
      left: 100px;
      top: 100px;
    }
    .login-form {
      width: 450px;
      background-color: #fff;
      border-radius: 20px;
      padding: 50px 50px 40px 50px;
      box-sizing: border-box;
      .title {
        padding: 0;
        margin: 0 0 40px 0;
      }
      .input-box {
        margin: 30px 0;
        img {
          margin-left: 4px;
        }
      }
      .rem-pwd {
        padding: 8px 0 0 20px;

        .ant-checkbox-wrapper {
          color: #bfc3d4;
        }
      }
      .sub-btn {
        margin-top: 60px;
        text-align: center;
      }
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 10px;
    color: #444;
  }
}
</style>

<style>
.login-container .input-box .ant-input {
  height: 50px;
  border-radius: 25px;
  padding-left: 40px;
}
</style>